import { useMutation, useQuery } from "@urql/vue";
import { MaybeRef } from "vue";
import { ListCostsVariables, client, listCostsQuery } from "..";
import { graphql } from "../gql";
import {
  Invoices_Statuses_Enum,
  ListCostsQueryVariables,
  ListPaymentsQueryVariables,
  type Invoices_Set_Input,
  type ListInvoicesQueryVariables,
} from "../gql/graphql";
import { deleteFile, uploadFiles } from "../simple-storage";
import { IncludesProp, expandIncludes } from "../utils/includes-prop";
import { ListPaymentsVariables, listPaymentsQuery } from "./payment";

export const listInvoicesQuery = graphql(/* GraphQL */ `
  query listInvoices(
    $where: invoices_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [invoices_order_by!] = {}
    $includeAmount: Boolean = false
    $includeAmountUsd: Boolean = false
    $includeAmountUsdWVat: Boolean = false
    $includeAmountWVat: Boolean = false
    $includeClosedBy: Boolean = false
    $includeCostsStatus: Boolean = false
    $includeCreatedAt: Boolean = false
    $includeCreatedBy: Boolean = false
    $includeCurrency: Boolean = false
    $includeDueDate: Boolean = false
    $includeId: Boolean = true
    $includeInvoiceDate: Boolean = false
    $includeInvoiceRef: Boolean = false
    $includeIsDeleted: Boolean = false
    $includeIsPaid: Boolean = false
    $includeLastPaymentDate: Boolean = false
    $includePaymentStatus: Boolean = false
    $includeRejectReason: Boolean = false
    $includeStatus: Boolean = false
    $includeSubsidiary: Boolean = false
    $includeSuppliery: Boolean = false
    $includeType: Boolean = false
    $includeValidatedBy: Boolean = false
  ) {
    invoices_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    invoices(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      amount @include(if: $includeAmount)
      amount_usd @include(if: $includeAmountUsd)
      amount_usd_w_vat @include(if: $includeAmountUsdWVat)
      amount_w_vat @include(if: $includeAmountWVat)
      closed_by @include(if: $includeClosedBy) {
        id
        first_name
        last_name
        additional_name
      }
      costs_status @include(if: $includeCostsStatus)
      created_at @include(if: $includeCreatedAt)
      created_by @include(if: $includeCreatedBy) {
        id
        first_name
        last_name
        additional_name
      }
      currency @include(if: $includeCurrency)
      due_date @include(if: $includeDueDate)
      id @include(if: $includeId)
      invoice_date @include(if: $includeInvoiceDate)
      invoice_ref @include(if: $includeInvoiceRef)
      is_deleted @include(if: $includeIsDeleted)
      is_paid @include(if: $includeIsPaid)
      last_payment_date @include(if: $includeLastPaymentDate)
      payment_status @include(if: $includePaymentStatus)
      reject_reason @include(if: $includeRejectReason)
      status @include(if: $includeStatus)
      subsidiary @include(if: $includeSubsidiary) {
        id
        display_name
      }
      suppliery @include(if: $includeSuppliery) {
        id
        display_name
      }
      type @include(if: $includeType)
      validated_by @include(if: $includeValidatedBy) {
        id
        first_name
        last_name
        additional_name
      }
    }
  }
`);

export type ListInvoicesIncludesValue =
  IncludesProp<ListInvoicesQueryVariables>;

export interface ListInvoicesVariables {
  limit?: MaybeRef<ListInvoicesQueryVariables["limit"]>;
  offset?: MaybeRef<ListInvoicesQueryVariables["offset"]>;
  order_by?: MaybeRef<ListInvoicesQueryVariables["order_by"]>;
  where?: MaybeRef<ListInvoicesQueryVariables["where"]>;
  includes: MaybeRef<ListInvoicesIncludesValue>;
}

export function useListInvoices(props: ListInvoicesVariables) {
  return useQuery({
    query: listInvoicesQuery,
    variables: {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListInvoicesQueryVariables,
  });
}

export async function listInvoices(props: ListInvoicesVariables) {
  const response = await client
    .query(listInvoicesQuery, {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListInvoicesQueryVariables)
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.invoices;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const getInvoiceQuery = graphql(/* GraphQL */ `
  query getInvoice($id: Int!) {
    invoices_by_pk(id: $id) {
      amount
      amount_usd
      amount_usd_w_vat
      amount_w_vat
      closed_by {
        additional_name
        first_name
        id
        last_name
      }
      costs_status
      created_at
      created_by {
        additional_name
        first_name
        id
        last_name
      }
      currency
      due_date
      id
      invoice_date
      invoice_ref
      is_deleted
      is_paid
      payment_status
      period_end
      period_start
      reject_reason
      status
      subsidiary {
        id
        display_name
      }
      suppliery {
        id
        display_name
      }
      type
      validated_by {
        additional_name
        first_name
        id
        last_name
      }
      files {
        id
        file_id
      }
      feeds(order_by: { created_at: desc }) {
        action
        changes
        created_at
        event_id
        user_id: hasura_user(path: "x-hasura-user-id")
        row_data
        row_id
        table_name
      }
    }
  }
`);

export function useGetInvoice(invoiceId: MaybeRef<number>) {
  return useQuery({
    query: getInvoiceQuery,
    variables: {
      // Hack: have to override the type. See known issues in README
      id: invoiceId as number,
    },
  });
}

export async function getInvoice(invoiceId: number) {
  const response = await client
    .query(getInvoiceQuery, {
      id: invoiceId,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.invoices_by_pk;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const updateInvoiceMutation = graphql(/* GraphQL */ `
  mutation updateInvoice($id: Int!, $data: invoices_set_input!) {
    update_invoices_by_pk(pk_columns: { id: $id }, _set: $data) {
      amount
      amount_usd
      amount_usd_w_vat
      amount_w_vat
      closed_by {
        additional_name
        first_name
        id
        last_name
      }
      costs_status
      created_at
      created_by {
        additional_name
        first_name
        id
        last_name
      }
      currency
      due_date
      id
      invoice_date
      invoice_ref
      is_deleted
      is_paid
      payment_status
      period_end
      period_start
      reject_reason
      status
      subsidiary {
        id
        display_name
      }
      suppliery {
        id
        display_name
      }
      type
      validated_by {
        additional_name
        first_name
        id
        last_name
      }
      files {
        id
        file_id
      }
      feeds(order_by: { created_at: desc }) {
        action
        changes
        created_at
        event_id
        user_id: hasura_user(path: "x-hasura-user-id")
        row_data
        row_id
        table_name
      }
    }
  }
`);

export function useUpdateInvoice() {
  return useMutation(updateInvoiceMutation);
}

export async function updateInvoice(id: number, data: Invoices_Set_Input) {
  const response = await client
    .mutation(updateInvoiceMutation, {
      id,
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const _data = response.data?.update_invoices_by_pk;
  if (!_data) {
    throw new Error("No data received");
  }

  return _data;
}

export const setInvoiceDeletedMutation = graphql(/* GraphQL */ `
  mutation setInvoiceDeleted($id: Int!, $is_deleted: Boolean!) {
    update_invoices_by_pk(
      pk_columns: { id: $id }
      _set: { is_deleted: $is_deleted }
    ) {
      id
    }
    update_costs(
      where: { invoice_id: { _eq: $id } }
      _set: { is_deleted: $is_deleted }
    ) {
      affected_rows
      returning {
        # Triggers cache update
        __typename
        id
      }
    }
  }
`);

export async function setInvoiceDeleted(id: number, is_deleted: boolean) {
  const response = await client
    .mutation(setInvoiceDeletedMutation, {
      id,
      is_deleted,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const _data = response.data?.update_invoices_by_pk?.id;
  if (!_data) {
    throw new Error("Invoice not found");
  }

  return _data;
}

export async function archiveInvoice(id: number) {
  return await setInvoiceDeleted(id, true);
}
export async function unarchiveInvoice(id: number) {
  return await setInvoiceDeleted(id, false);
}

export const updateInvoicesMutation = graphql(/* GraphQL */ `
  mutation updateInvoices($ids: [Int!]!, $data: invoices_set_input!) {
    update_invoices(where: { id: { _in: $ids } }, _set: $data) {
      affected_rows
      returning {
        # Triggers cache update
        __typename
        id
      }
    }
  }
`);

export async function updateInvoices(
  ids: number | number[],
  data: Invoices_Set_Input
) {
  const response = await client
    .mutation(updateInvoicesMutation, {
      ids,
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const _data = response.data?.update_invoices;
  if (!_data) {
    throw new Error("No data received");
  }

  return _data;
}

export async function changeInvoiceStatusOpen(ids: number | number[]) {
  return updateInvoices(ids, {
    status: Invoices_Statuses_Enum.Open,
  });
}
export async function changeInvoiceStatusReopen(ids: number | number[]) {
  return updateInvoices(ids, {
    status: Invoices_Statuses_Enum.Open,
    validated_by_id: null,
    reject_reason: null,
  });
}
export async function changeInvoiceStatusReject(
  ids: number | number[],
  userId: string,
  rejectionMessage: string
) {
  return updateInvoices(ids, {
    status: Invoices_Statuses_Enum.Rejected,
    reject_reason: rejectionMessage,
    validated_by_id: userId,
  });
}
export async function changeInvoiceStatusValidated(
  ids: number | number[],
  userId: string
) {
  return updateInvoices(ids, {
    status: Invoices_Statuses_Enum.Validated,
    validated_by_id: userId,
  });
}
export async function changeInvoiceStatusPaid(
  ids: number | number[],
  userId: string
) {
  // TODO: Pass closed_at
  return updateInvoices(ids, {
    status: Invoices_Statuses_Enum.Paid,
    closed_by_id: userId,
  });
}

export const addInvoiceFilesMutation = graphql(/* GraphQL */ `
  mutation addInvoiceFiles($objects: [invoices_files_insert_input!]!) {
    insert_invoices_files(objects: $objects) {
      affected_rows
      returning {
        __typename
        invoice {
          __typename
        }
      }
    }
  }
`);

export async function addFilesToInvoice(
  id: number,
  files: File | File[] | FileList
) {
  const fileIds = await uploadFiles(files);
  const fileIdArray = Array.isArray(fileIds) ? fileIds : [fileIds];

  const insertResponse = await client
    .mutation(addInvoiceFilesMutation, {
      objects: fileIdArray.map((file_id) => ({
        invoice_id: id,
        file_id,
      })),
    })
    .toPromise();

  if (insertResponse.error) {
    throw new Error(insertResponse.error.toString());
  }

  return insertResponse.data?.insert_invoices_files?.affected_rows;
}

export const removeInvoiceFilesMutation = graphql(/* GraphQL */ `
  mutation removeInvoiceFiles($invoice_id: Int!, $file_ids: [uuid!]!) {
    delete_invoices_files(
      where: { invoice_id: { _eq: $invoice_id }, file_id: { _in: $file_ids } }
    ) {
      affected_rows
      returning {
        __typename
        file_id
        invoice {
          __typename
        }
      }
    }
  }
`);

export async function removeFilesFromInvoice(id: number, file_ids: string[]) {
  const deleteResponse = await client
    .mutation(removeInvoiceFilesMutation, {
      invoice_id: id,
      file_ids,
    })
    .toPromise();

  if (deleteResponse.error) {
    throw new Error(deleteResponse.error.toString());
  }

  const deletedFileIds: string[] =
    deleteResponse.data?.delete_invoices_files?.returning?.map(
      ({ file_id }) => file_id
    ) ?? [];

  if (deletedFileIds.length > 0) {
    await Promise.all(deletedFileIds.map((fileId) => deleteFile(fileId)));
  }

  return deletedFileIds;
}

export const archiveAndDeleteInvoiceMutation = graphql(/* GraphQL */ `
  mutation archiveAndDeleteInvoice($id: Int!) {
    update_invoices_by_pk(
      pk_columns: { id: $id }
      _set: { is_deleted: true }
    ) {
      __typename
    }
    update_costs(
      where: { invoice_id: { _eq: $id } }
      _set: { is_deleted: true }
    ) {
      affected_rows
    }
    delete_invoices_by_pk(id: $id) {
      id
    }
  }
`);

export async function archiveAndDeleteInvoice(id: number) {
  const response = await client
    .mutation(archiveAndDeleteInvoiceMutation, {
      id,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const _data = response.data?.delete_invoices_by_pk?.id;
  if (!_data) {
    throw new Error("The invoice not found");
  }

  return _data;
}

export const deleteInvoiceMutation = graphql(/* GraphQL */ `
  mutation deleteInvoice($id: Int!) {
    delete_invoices_by_pk(id: $id) {
      id
    }
  }
`);

export async function deleteInvoice(id: number) {
  const response = await client
    .mutation(deleteInvoiceMutation, {
      id,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const _data = response.data?.delete_invoices_by_pk?.id;
  if (!_data) {
    throw new Error("The invoice not found");
  }

  return _data;
}

export type ListInvoiceCostsVariables = Omit<ListCostsVariables, "where">;

export function useListInvoiceCosts(
  invoiceId: MaybeRef<number>,
  props: ListInvoiceCostsVariables
) {
  return useQuery({
    query: listCostsQuery,
    variables: {
      ...expandIncludes(props.includes),
      where: { invoice_id: { _eq: invoiceId } },
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      // Hack: have to override the type. See known issues in README
    } as ListCostsQueryVariables,
  });
}

export async function listInvoiceCosts(invoiceId: number) {
  const response = await client
    .query(listCostsQuery, {
      where: { invoice_id: { _eq: invoiceId } },
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.costs;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export type ListInvoicePaymentsVariables = Omit<ListPaymentsVariables, "where">;

export function useListInvoicePayments(
  invoiceId: MaybeRef<number>,
  props: ListInvoicePaymentsVariables
) {
  return useQuery({
    query: listPaymentsQuery,
    variables: {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: { invoices_payments: { invoice_id: { _eq: invoiceId } } },
      // Hack: have to override the type. See known issues in README
    } as ListPaymentsQueryVariables,
  });
}

export async function listInvoicePayments(
  id: number,
  props: ListInvoicePaymentsVariables
) {
  const response = await client
    .query(listPaymentsQuery, {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: { invoices_payments: { invoice_id: id } },
      // Hack: have to override the type. See known issues in README
    } as ListPaymentsQueryVariables)
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.payments;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const createInvoiceMutation = graphql(/* GraphQL */ `
  mutation createInvoice($data: invoices_insert_input = {}) {
    insert_invoices_one(object: $data) {
      id
    }
  }
`);

export function useCreateInvoice() {
  return useMutation(createInvoiceMutation);
}

export const getInvoiceAmountAndPaymentsQuery = graphql(/* GraphQL */ `
  query getInvoiceAmountAndPayments($id: Int!) {
    invoices_by_pk(id: $id) {
      amount_w_vat
    }
    payments_aggregate(
      where: { invoices_payments: { invoice_id: { _eq: $id } } }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`);

export async function getInvoiceRemainingPayment(
  id: MaybeRef<number>
): Promise<number> {
  const response = await client
    .query(getInvoiceAmountAndPaymentsQuery, {
      // Hack: have to override the type. See known issues in README
      id: id as number,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  if (!response.data) {
    throw new Error("No data received");
  }
  const invoiceData = response.data.invoices_by_pk;
  if (!invoiceData) {
    throw new Error("The invoice not found");
  }
  const sum = response.data.payments_aggregate.aggregate?.sum?.amount;

  // If sum is null it means that no payment has been made yet
  if (!sum) return invoiceData.amount_w_vat;

  return invoiceData.amount_w_vat - sum;
}

export const getInvoicesRemainingPaymentQuery = graphql(/* GraphQL */ `
  query getInvoicesRemainingPayment($ids: [Int!]!) {
    remaining_amount: invoices_remaining_amount(where: { id: { _in: $ids } }) {
      id
      remaining
      currency
    }
  }
`);

export function useGetInvoicesRemainingPayment(
  ids: MaybeRef<number | number[]>,
  pause?: MaybeRef<boolean>
) {
  return useQuery({
    query: getInvoicesRemainingPaymentQuery,
    variables: {
      ids: ids as number | number[],
    },
    pause,
    context: {
      requestPolicy: "cache-first",
    },
  });
}

export async function getInvoicesRemainingPayment(
  ids: MaybeRef<number | number[]>
) {
  const response = await client
    .query(
      getInvoicesRemainingPaymentQuery,
      {
        // Hack: have to override the type. See known issues in README
        ids: ids as number | number[],
      },
      {
        requestPolicy: "cache-first",
      }
    )
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.remaining_amount;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}
